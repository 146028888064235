<template>
    <div class="order-delivery">
        <h1 class="individual-title" v-if="!isForCheckOut">{{ $t('order.individualOrder') }}</h1>
        <div class="order-delivery-item">
            <h4>{{ $t('common.deliveryDate') }}</h4>

            <slot name="unique-kits-names" v-if="isForCheckOut" />

            <h5 v-if="description" class="description">{{ description }}</h5>
            <div v-if="isCalendarAndDeliveryTimesShown">
                <h5 v-if="isForCheckOut">{{ $t('mealKits.regularKits') }}</h5>
                <h4 class="subtitle" v-if="!isForCheckOut">{{ $t('order.deliveryDateHelp') }}</h4>
                <CRDatepicker
                    class="calendar"
                    inline
                    v-model="data.deliveryStartDate"
                    :allowed-dates="allowedDeliveryDates"
                    model-type="yyyy-MM-dd"
                    no-today
                />
            </div>
        </div>

        <div class="order-delivery-item">
            <h4>{{ $t('common.deliveryTime') }}</h4>

            <slot name="unique-kits-dates" v-if="isForCheckOut" />

            <div v-if="isCalendarAndDeliveryTimesShown">
                <h5 v-if="isForCheckOut">{{ $t('mealKits.regularKits') }}</h5>
                <CRRadio
                    class="radio"
                    v-for="time in deliveryTimes"
                    v-model="data.deliveryTimeId"
                    :key="time.id"
                    :value="time.id"
                >
                    {{ getLabelForDeliveryTime(time) }}
                </CRRadio>
            </div>
        </div>

        <div class="order-delivery-item">
            <h4>{{ $t('common.deliveryMethod') }}</h4>
            <CRRadio
                class="radio"
                v-for="method in deliveryMethods"
                v-model="data.deliveryMethodId"
                :key="method.id"
                :value="method.id"
            >
                {{ $t(`deliveryMethods.${method.type}`) }}
            </CRRadio>
            <CRTextarea
                class="textarea"
                v-model="v$.data.extraInformation.$model"
                :label="$t('deliveryInfo.informationToCourier')"
                :placeholder="$t('placeholder.enterInformationToCourier')"
                :max-length="500"
                :errors="v$.data.extraInformation.$errors"
                is-vuelidate
            />
        </div>
        <slot name="btn-section" :delivery-data="data" />
    </div>
</template>

<script>
    import CRTextarea from '@/components/Common/Textarea/CRTextarea.vue';
    import CRRadio from '@/components/Common/Radio/CRRadio.vue';
    import CRDatepicker from '@/components/Common/Datepicker/CRDatepicker.vue';
    import { DeliveryTimeApi, DeliveryMethodApi } from '@/api';
    import { getLabelForDeliveryTime } from '@/helpers/DeliveryDateHelper';
    import { mapGetters } from 'vuex';
    import { useVuelidate } from '@vuelidate/core';
    import { helpers, minLength, maxLength } from '@vuelidate/validators';

    export default {
        name: 'OrderDelivery',
        components: { CRDatepicker, CRRadio, CRTextarea },
        props: {
            allowedDeliveryDates: {
                type: Array,
                default: () => [],
            },
            description: {
                type: String,
                default: '',
            },
            isForCheckOut: {
                type: Boolean,
                default: false,
            },
            isCalendarAndDeliveryTimesShown: {
                type: Boolean,
                default: true,
            },
        },
        setup() {
            return { v$: useVuelidate() };
        },
        data() {
            return {
                deliveryTimes: [],
                deliveryMethods: [],
                getLabelForDeliveryTime,

                data: {
                    deliveryStartDate: '',
                    deliveryTimeId: 0,
                    startTime: '',
                    endTime: '',
                    deliveryMethodId: 0,
                    extraInformation: '',
                },
            };
        },
        validations() {
            return {
                data: {
                    extraInformation: {
                        minLength: helpers.withMessage(
                            ({ $params }) => this.$t('errors.minLength', { amount: $params.min }),
                            minLength(3)
                        ),
                        maxLength: helpers.withMessage(
                            ({ $params }) => this.$t('errors.maxLength', { amount: $params.max }),
                            maxLength(500)
                        ),
                    },
                },
            };
        },
        computed: {
            ...mapGetters('orderInformation', ['deliveryInformation', 'buyerInformation']),
            ...mapGetters('city', ['selectedCity']),
        },
        watch: {
            'selectedCity'() {
               this.getDeliveryTimes();
            },
            'allowedDeliveryDates.length'() {
                this.data.deliveryStartDate = this.allowedDeliveryDates[0];
            },
            'data.deliveryTimeId'(id) {
                const selectedDeliveryTime = this.deliveryTimes.find((time) => time.id === id);

                if (selectedDeliveryTime) {
                    this.data.startTime = selectedDeliveryTime.startTime;
                    this.data.endTime = selectedDeliveryTime.endTime;
                }
            },
        },
        methods: {
            setDataForCheckOutIfExistInState() {
                if (this.isForCheckOut) {
                    if (this.deliveryInformation?.deliveryData?.regular) {
                        if (
                            this.allowedDeliveryDates.includes(
                                this.deliveryInformation.deliveryData.regular.deliveryStartDate
                            )
                        ) {
                            this.data.deliveryStartDate =
                                this.deliveryInformation.deliveryData.regular.deliveryStartDate;
                        } else {
                            this.data.deliveryStartDate = this.allowedDeliveryDates[0];
                        }

                        this.data.startTime = this.deliveryInformation.deliveryData.regular.startTime;
                        this.data.endTime = this.deliveryInformation.deliveryData.regular.endTime;
                        this.data.deliveryTimeId = this.deliveryInformation.deliveryData.regular.deliveryTimeId;
                    }

                    if (this.deliveryInformation) {
                        this.data.deliveryMethodId = this.deliveryInformation.deliveryMethodId;
                        this.data.extraInformation = this.deliveryInformation.extraInformation;
                    }
                } else {
                    if (this.deliveryInformation) {
                        if (
                            this.allowedDeliveryDates.includes(this.deliveryInformation.deliveryData.deliveryStartDate)
                        ) {
                            this.data.deliveryStartDate = this.deliveryInformation.deliveryData.deliveryStartDate;
                        } else {
                            this.data.deliveryStartDate = this.allowedDeliveryDates[0];
                        }

                        this.data.startTime = this.deliveryInformation.deliveryData.startTime;
                        this.data.endTime = this.deliveryInformation.deliveryData.endTime;
                        this.data.deliveryTimeId = this.deliveryInformation.deliveryData.deliveryTimeId;

                        this.data.deliveryMethodId = this.deliveryInformation.deliveryMethodId;
                        this.data.extraInformation = this.deliveryInformation.extraInformation;
                    }
                }
            },

            async getDeliveryTimes() {
                try {
                    // @todo need refactoring not found city. 
                    const response = await DeliveryTimeApi.getAll(
                        this.buyerInformation === undefined || this.buyerInformation === null ? this.selectedCity.id : this.buyerInformation.cityData.id
                    );

                    this.deliveryTimes = response.data;
                    this.data.deliveryTimeId = this.deliveryTimes[0].id;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async getDeliveryMethods() {
                try {
                    const response = await DeliveryMethodApi.getAll({ isActive: true });
                    this.deliveryMethods = response.data;
                    this.data.deliveryMethodId = this.deliveryMethods[0].id;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },
        async mounted() {
            await this.getDeliveryTimes();
            await this.getDeliveryMethods();

            this.setDataForCheckOutIfExistInState();
        },
    };
</script>

<style lang="scss" scoped>
    .order-delivery {
        width: 100%;
        .individual-title {
            margin-bottom: 32px;
        }

        .order-delivery-item {
            margin-bottom: 32px;

            &:last-child {
                margin-bottom: 0;
            }

            h4,
            h5 {
                margin-bottom: 18px;
            }

            .description {
                color: $grey-static;
                margin-bottom: 32px;
            }

            .radio {
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .textarea {
                margin-top: 28px;
                height: 115px;
            }

            .subtitle {
                color: $grey-form-label;
            }
        }
    }
</style>
